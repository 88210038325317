<template>
  <v-row class="d-flex flex-column">
    <v-col>
      <v-row>
        <v-breadcrumbs :items="breadcrumbs" class="px-4">
          <template v-slot:item="{ item }">
            <v-breadcrumbs-item
              :to="item.to"
              :disabled="item.disabled"
            >
              {{ item.text.toUpperCase() }}
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
      </v-row>

      <v-form
        ref="form"
        v-model="form.valid"
        lazy-validation>
        <v-row class="pt-1" :class="{'opacity': ui.loading }">
          <v-progress-circular class="infiniteLoading"
                               v-if="ui.loading"
                               :size="50"
                               color="amber"
                               indeterminate
          ></v-progress-circular>
          <v-col cols="12">
            <v-alert
              v-model="ui.alert.enable"
              :dismissible="ui.alert.dismissible"
              :icon="ui.alert.icon"
              :type="ui.alert.type"
              class="mb-0 mt-3 mb-3"
            >
              {{ ui.alert.message }}
            </v-alert>
          </v-col>
          <v-col cols="12" sm="12" class="pt-0">
            <v-card outlined
                    :disabled="ui.disabled"
                    class="rounded-lg overflow-hidden mb-6">
              <template slot="progress">
                <v-progress-linear
                  color="blue-grey darken-3"
                  height="6"
                  indeterminate
                ></v-progress-linear>
              </template>
              <v-row
                style="border-radius: 0"
                class="blue py-6 px-5 px-sm-9">
                <v-col cols="12" class="pb-0">
                  <v-toolbar-title class="title white--text pl-0">
                    {{ $vuetify.lang.t('$vuetify.sites.catalogue.edit.ui.title') }}
                  </v-toolbar-title>
                </v-col>
              </v-row>
              <v-card-text class="px-0 pb-0">
                <div class="px-5 px-sm-9 pt-6">
                  <v-row>
                    <!-- Add catalogue informations component -->
                    <v-col cols="12">
                      <div class="overline text-uppercase font-weight-bold pb-2 pt-2">
                        <v-icon class="pl-3 pr-3" color="purple">mdi-tag-text-outline</v-icon>
                        <span class="black--text text-center mx-auto">
                          {{ $vuetify.lang.t('$vuetify.sites.catalogue.edit.ui.ui.form.general.title') }}
                        </span>
                      </div>
                      <v-divider></v-divider>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="form.data.name"
                        :counter="45"
                        :label="$vuetify.lang.t('$vuetify.sites.catalogue.edit.ui.ui.form.general.inputs.name.name')+'*'"
                        :hint="$vuetify.lang.t('$vuetify.sites.catalogue.edit.ui.ui.form.general.inputs.name.hint')"
                        :rules="form.rules.name"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="10" sm="6" v-if="!_.isEmpty(form.default_data.image)">
                      <v-img :src="form.default_data.image" max-height="200" contain
                         ></v-img>
                    </v-col>
                    <v-col cols="12" sm="6" v-if="!_.isEmpty(form.default_data.image)">
                    </v-col>
                    <v-col cols="10" sm="5">
                      <v-file-input
                        v-model="form.data.icon"
                        accept="image/*"
                        :rules="form.rules.icon"
                        :label="$vuetify.lang.t('$vuetify.sites.catalogue.edit.ui.ui.form.general.inputs.icon.name')"
                        :messages="$vuetify.lang.t('$vuetify.sites.catalogue.edit.ui.ui.form.general.inputs.icon.hint')"
                      ></v-file-input>
                    </v-col>
                    <v-col cols="1" sm="1" class="align-self-center text-center">
                      <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            color="green"
                            v-bind="attrs"
                            v-on="on"
                            target="_blank"
                           href="https://www.flaticon.com/fr/">
                            <v-icon>mdi-lightbulb-on-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $vuetify.lang.t('$vuetify.sites.catalogue.edit.ui.ui.form.general.inputs.icon.button.hint') }}</span>
                      </v-tooltip>
                    </v-col>
                    <!-- Add catalogue description component  -->
                    <v-col>
                      <div class="overline text-uppercase font-weight-bold pb-2 pt-2">
                        <v-icon class="pl-3 pr-3" color="purple">mdi-note-text-outline</v-icon>
                        <span class="black--text text-center mx-auto">
                          {{ $vuetify.lang.t('$vuetify.sites.catalogue.edit.ui.ui.form.general.description.title') }}
                        </span>
                      </div>
                      <v-divider></v-divider>
                    </v-col>
                    <v-col cols="12" sm="12">
                      <v-textarea
                        v-model="form.data.description"
                        outlined
                        persistent-hint
                        :placeholder="$vuetify.lang.t('$vuetify.sites.catalogue.edit.ui.ui.form.general.description.placeholder')"
                        :hint="$vuetify.lang.t('$vuetify.sites.catalogue.edit.ui.ui.form.general.description.hint')"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <!-- Add product from product family  -->
          <v-col cols="12" sm="12" class="pt-0">
            <v-card class="rounded-lg overflow-hidden mb-6" :disabled="ui.disabled">
              <v-card-text class="px-0 pb-0">
                <div class="px-5 px-sm-9 pt-6">
                  <v-row>
                    <v-col cols="12" class="">
                      <div class="overline text-uppercase font-weight-bold pb-2 pt-2">
                        <v-icon class="pl-3 pr-3" color="purple">mdi-text-box-plus-outline</v-icon>
                        <span class="black--text text-center mx-auto">
                          {{ $vuetify.lang.t('$vuetify.sites.catalogue.edit.ui.ui.form.content.families.name') }}
                        </span>
                        <v-tooltip left max-width="30vw">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon class="pl-3 pr-3 text-right float-right" color="red darken-4" v-bind="attrs"
                                    v-on="on">mdi-account-question-outline
                            </v-icon>
                          </template>
                          <span> {{ $vuetify.lang.t('$vuetify.sites.catalogue.edit.ui.ui.form.content.families.hint') }}</span>
                        </v-tooltip>
                      </div>
                      <v-divider></v-divider>
                    </v-col>
                    <v-col cols="12" sm="12">
                      <v-autocomplete
                        v-model="form.data.selected_families"
                        item-text="path"
                        return-object
                        :items="families"
                        :label="$vuetify.lang.t('$vuetify.sites.catalogue.edit.ui.ui.form.content.families.select.add')"
                        clearable
                        dense
                        deletable-chips
                        small-chips
                        hide-no-data
                        multiple
                        hide-selected
                      >
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                </div>
              </v-card-text>
              <v-row dense class="mt-4 ml-auto mr-auto align-items-center align-content-center pb-8"
                     style="display: flex;width: 95%;">
                <family-refine-component v-for="family in form.data.selected_families"
                                              ref="productFamilyCard"
                                              :should_reset="should_reset"
                                              :pre_selected = getSelectedProductsByFamily(family.id)
                                              :key="family.id"
                                              :family="family"
                                              @updateSelectedProductsFromFamily="updateRemovedFromFamily">
                </family-refine-component>
              </v-row>
            </v-card>
          </v-col>
          <!-- Add product from list of products -->
          <v-col cols="12" sm="12" class="pt-0" >
            <v-card class="rounded-lg overflow-hidden" :disabled="ui.disabled">
              <v-card-text class="px-0 pb-0">
                <div class="px-5 px-sm-9 pt-6">
                  <v-row>
                    <v-col cols="12" class="">
                      <div class="overline text-uppercase font-weight-bold pb-2 pt-2">
                        <v-icon class="pl-3 pr-3" color="purple">mdi-text-box-plus-outline</v-icon>
                        <span class="black--text text-center mx-auto">
                          {{ $vuetify.lang.t('$vuetify.sites.catalogue.edit.ui.ui.form.content.products.name') }}
                        </span>
                        <v-tooltip left max-width="30vw">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon class="pl-3 pr-3 text-right float-right" color="red darken-4" v-bind="attrs"
                                    v-on="on">mdi-account-question-outline
                            </v-icon>
                          </template>
                          <span>                          {{
                              $vuetify.lang.t('$vuetify.sites.catalogue.edit.ui.ui.form.content.products.hint')
                                                          }}
</span>
                        </v-tooltip>
                      </div>
                      <v-divider></v-divider>
                    </v-col>
                    <v-col cols="12" sm="12">
                    </v-col>
                  </v-row>
                </div>
              </v-card-text>
              <v-row dense class="mt-4 ml-auto mr-auto align-items-center align-content-center"
                     style="display: flex;width: 95%;">
                <v-col cols="12">
                  <v-hover v-slot="{ hover }">
                    <v-card class="mr-3 hover-effect" :elevation="hover ? 12 : 2">
                      <v-card-text class="text-center overline justify-center">
                        <v-dialog
                          width="80vw"
                          v-model="dialog_products"
                          persistent>
                          <AddProductComponent :site="$route.params.id"
                                                            :initial_selected_products="initial_products"
                                                            :parent_additive_products="form.data.additive_products"
                                                            :on-cancel="()=>{dialog_products = false}"
                                                            @savedSelection="saveFromIndividualProductAdd"></AddProductComponent>
                        </v-dialog>
                        <div @click="dialog_products = true">
                          <img v-if="_.isEmpty(form.data.selected_add_products_individually)" class="mt-4" src="../../../assets/box_add.png" style="height: 70px">
                          <img v-else class="mt-4" src="../../../assets/select.png" style="height: 70px">

                        <p v-if="_.isEmpty(form.data.selected_add_products_individually)">Rajouter</p>
                        <p v-else>Voir selection produits<br> {{_.size(form.data.selected_add_products_individually) }} produits</p>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-hover>
                </v-col>
              </v-row>
              <v-card-actions class="d-xs-block px-3 px-sm-7 mt-4">
                <v-btn
                  text
                  v-on:click="reset()">
                  {{ $vuetify.lang.t('$vuetify.global.form.btn_cancel') }}
                </v-btn>
                <v-btn
                  color="primary"
                  text
                  v-on:click="save()">
                  {{ $vuetify.lang.t('$vuetify.global.form.btn_save') }}
                </v-btn>
                <!--  Confirm dialog if no products selected              -->
                <v-dialog v-model="dialog_confirm" persistent max-width="330">
                  <v-card color="blue-grey lighten-5">
                    <v-card-title class="headline">
                      <v-img src="@/assets/safety-box.png" class="mt-2"  height="80" contain></v-img>
                    </v-card-title>
                    <v-card-text class="text-center mt-4 overline">
                      {{ $vuetify.lang.t('$vuetify.sites.catalogue.edit.ui.ui.confirm.no_products') }}
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="gray darken-1"
                        text
                        @click="dialog_confirm = false">
                        {{ $vuetify.lang.t('$vuetify.global.form.btn_cancel') }}
                      </v-btn>
                      <v-btn
                        color="green darken-1"
                        text
                        @click="submit()">
                        {{ $vuetify.lang.t('$vuetify.global.form.btn_confirm') }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-form>
    </v-col>
    <v-fab-transition >
      <div class="v-btn-right-bottom">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="blue-grey lighten-1"
              fab
              small
              dark
              class="ml-2"
              v-bind="attrs"
              v-on="on"
              :to="{name:'SiteCatalogueList'}"
              :disabled="ui.disabled">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
          </template>
          <span>{{ $vuetify.lang.t('$vuetify.global.back') }}</span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="green lighten-1"
              small
              fab
              dark
              class="ml-2"
              v-bind="attrs"
              v-on="on"
              @click="save"
              :disabled="ui.disabled">
              <v-icon>mdi-content-save-outline</v-icon>
            </v-btn>
          </template>
          <span>{{ $vuetify.lang.t('$vuetify.global.form.btn_save') }}</span>
        </v-tooltip>
      </div>
    </v-fab-transition>
  </v-row>
</template>
<script>
import _ from 'lodash'
import FamilyRefineComponent from './components/FamilyRefineComponent'
import AddProductComponent from './components/AddProductComponent'

export default {
  components: { FamilyRefineComponent, AddProductComponent },
  data: () => ({
    ui: {
      loading: false,
      disabled: false,
      alert: {
        enable: false,
        dismissible: false,
        icon: 'mdi-check',
        type: 'info',
        message: 'Test'
      }
    },
    breadcrumbs: [],
    site_name: 'default',
    import_all: false,
    initial_products: [],
    should_reset: false,
    dialog_products: false, // opens dialog that allows for individual selection of products
    dialog_confirm: false, // opens dialog that asks user to confirm submission of form
    form: {
      valid: false,
      default_data: {},
      data: {
        name: '',
        description: '',
        icon: null,
        removed_products: [],
        selected_families: [], // array of selected families in the autocomplete
        families_to_remove: [], // array of product family ids to remove
        selected_add_products_individually: [], // array of product ids that that have been selected individually
        selected_add_products: [], // array of product ids that have been selected individually from product family component
        selected_families_import_all: [], // array of family ids where we import all products
        additive_products: [] // array of product ids that are additive
      },
      rules: {}
    },
    families: [] // fetched families that belong to site
  }),
  computed: {},
  watch: {
  },
  methods: {
    alert (type, icon, message, dismissible = true) {
      this.ui.alert.icon = icon
      this.ui.alert.type = type
      this.ui.alert.message = message
      this.ui.alert.dismissible = dismissible
      this.ui.alert.enable = true
    },
    reset () {
      this.should_reset = !this.should_reset
      this.$forceUpdate()
      this.ui.alert.enable = false
      this.$refs.form.resetValidation()
      this.form.data.icon = null
      this.form.data.removed_products = []

      setTimeout(() => {
        this.form.data.name = this.form.default_data.value
        this.form.data.description = this.form.default_data.description
        this.form.data.selected_add_products_individually = this.form.default_data.products.map(p => p.id)
        this.initial_products = this.form.default_data.products.map(p => p.id)
        this.form.data.selected_families = this.form.default_data.families
        this.form.data.families_import_all = _.filter(this.form.default_data.families, ['is_additive', 1])

        // get all product ids that are additive
        this.form.data.additive_products = _.reduce(this.form.default_data.products, function (additive, product) {
          if (product.is_additive === 1) {
            additive.push(product.id)
          }
          return additive
        }, [])
      })

      this.getSelectedProductsByFamily()
    },
    validate () {
      return this.$refs.form.validate()
    },
    goTop () {
      this.$vuetify.goTo(0)
    },
    /**
     * Filter through all the products that belong to the catalogue and find the ones that belong to the family sent in parameter
     * Used to pre-select products in SiteFamilyProductSelectComponent
     * */
    getSelectedProductsByFamily (familyId) {
      return _.reduce(this.form.default_data.products, function (family, product) {
        if (product.families.includes(familyId)) {
          family.push(product.id)
        }
        return family
      }, [])
    },
    // EVENTS
    /**
     * Emitter : SiteCatalogueAddProductComponent
     * Event name : 'savedSelection'
     * Payload: array of added and array of removed product ids
     *
     * Saves products that have been selected by SiteCatalogueAddProductComponent
     * Selection of products that have been added individually from a list of available products
     *
     * */
    saveFromIndividualProductAdd (payload) {
      this.form.data.selected_add_products_individually = payload.added
      this.form.data.removed_products.push(payload.removed)
    },
    /**
     * Emitter : SiteFamilyRefineComponent
     * Event name : updateSelectedProductsFromFamily
     * Payload : array of selected products and removed products
     *
     * */
    updateRemovedFromFamily (payload) {
      this.form.data.removed_products.push(payload.removed)
    },
    /**
     * Cleans data from FamilyEditComponent
     * If products have been selected individually push products ids to selected_add_products[]
     * If all products from family should be imported push family id to selected_families_import_all[]
     *
     * */
    cleanProductFamilyCardComponent () {
      const self = this
      this.form.data.selected_add_products = []
      this.form.data.selected_families_import_all = []
      _.forEach(this.$refs.productFamilyCard, function (familyCard) {
        if (familyCard.import_all === false && !_.isEmpty(familyCard.products)) {
          self.form.data.selected_add_products = _.uniq(self.form.data.selected_add_products.concat(familyCard.products))
        } else if (familyCard.import_all === true) {
          self.form.data.selected_families_import_all.push(familyCard.family.id)
        }
      })
    },
    /**
     * Clean form before submit && last minute checks
     * */
    save () {
      // validates form
      if (!this.validate()) {
        this.goTop()
        return false
      }
      // cleans and fetches all products selected in product families cards
      this.cleanProductFamilyCardComponent()

      // checks and asks user to confirm if he wants to submit without any products
      if (_.isEmpty(this.form.data.selected_families_import_all) && _.isEmpty(this.form.data.selected_add_products) && _.isEmpty(this.form.data.selected_add_products_individually) && _.isEmpty(this.form.data.selected_families)) {
        this.dialog_confirm = true
      } else this.submit()
    },
    // API CALLS
    /**
     * Fetches product families that are available for current site
     * @return {Promise<void>}
     */
    async fetchFamiliesAvailable () {
      try {
        this.response = (await this.axios.get('/site/' + this.$route.params.id + '/catalogue/families', {
          headers: {
            token: this.$session.get('auth_credentials').token
          }
        })).data
        // save families data
        this.families = this.response.families
      } catch (e) {
        if (!this._.isUndefined(e.response?.status)) {
          switch (parseInt(e.response.status)) {
            // site not found
            case 404:
              await this.$router.push({
                name: 'Home',
                params: {
                  alert: {
                    enable: true,
                    dismissible: true,
                    icon: 'mdi-alert',
                    type: 'warning',
                    message: this.$vuetify.lang.t('$vuetify.sites.catalogue.edit.notification.site_not_found')
                  }
                }
              })
              break
            // product family fetch error
            default:
              this.alert('error', 'mdi-alert-octagon', this.$vuetify.lang.t('$vuetify.sites.catalogue.edit.notification.load_families'))
          }
          this.Sentry.captureException(e)
        }
      } finally {
        this.ui.loading = false
        this.ui.disabled = false
      }
    },
    /**
     * Fetches site catalogue category details
     * */
    async fetchSiteCatalogueCategory () {
      try {
        this.form.default_data = (await this.axios.get('/site/' + this.$route.params.id + '/catalogue/one/' + this.$route.params.catalogue_id, {
          headers: {
            token: this.$session.get('auth_credentials').token
          }
        })).data
        this.reset()

        // update name of site
        this.breadcrumbs.find(e => e.id === 1).text = this.response.site
      } catch (e) {
        if (!this._.isUndefined(e.response?.status)) {
          switch (parseInt(e.response.status)) {
            // site not found
            case 404:
              await this.$router.push({
                name: 'Home',
                params: {
                  alert: {
                    enable: true,
                    dismissible: true,
                    icon: 'mdi-alert',
                    type: 'warning',
                    message: this.$vuetify.lang.t('$vuetify.sites.catalogue.edit.notification.site_not_found')
                  }
                }
              })
              break
            // category not found
            case 405:
              await this.$router.push({
                name: 'SiteCatalogueList',
                params: {
                  alert: {
                    enable: true,
                    dismissible: true,
                    icon: 'mdi-alert',
                    type: 'warning',
                    message: this.$vuetify.lang.t('$vuetify.sites.catalogue.edit.notification.catalogue_not_found')
                  }
                }
              })
              break
            // default
            default:
              this.alert('error', 'mdi-alert-octagon', this.$vuetify.lang.t('$vuetify.sites.catalogue.edit.notification.unknown_fetch'))
          }
          this.Sentry.captureException(e)
        }
      } finally {
        this.ui.loading = false
        this.ui.disabled = false
      }
    },
    /**
     * Submits form
     * @return {Promise<boolean>}
     */
    async submit () {
      this.dialog_confirm = false
      this.goTop()

      try {
        this.ui.alert.enable = false
        this.ui.loading = true
        this.ui.disabled = true

        const formData = new FormData()
        formData.append('name', this.form.data.name) // name of site catalogue category
        formData.append('description', this.form.data.description) // short description of site catalogue category

        // array of family ids from where we should import all products
        _.forEach(this.form.data.selected_families_import_all, function (key, value) {
          formData.append('selected_families_import_all_products[' + value + ']', key)
        })

        // array of selected products ids selected individually from product family && products selected individually from a list of products
        let products = []
        products = _.uniq(this.form.data.selected_add_products.concat(this.form.data.selected_add_products_individually))
        _.forEach(products, function (key, value) {
          formData.append('selected_add_products[' + value + ']', key)
        })

        _.forEach(_.flatten(this.form.data.removed_products), function (key, value) {
          formData.append('removed_products[' + value + ']', key)
        })

        // array of selected families
        let families = []
        families = this.form.data.selected_families.map(f => f.id)
        _.forEach(families, function (key, value) {
          formData.append('selected_families[' + value + ']', key)
        })

        // array of removed families
        let familiesRemoved = []
        familiesRemoved = _.xor(this.form.default_data.families.map(e => e.id), this.form.data.selected_families.map(e => e.id))
        _.forEach(familiesRemoved, function (key, value) {
          formData.append('removed_families[' + value + ']', key)
        })

        formData.append('icon', this.form.data.icon)

        await this.axios.post('/site/' + this.$route.params.id + '/catalogue/edit/' + this.$route.params.catalogue_id,
          formData, {
            headers: {
              token: this.$session.get('auth_credentials').token
            }
          })
        await this.fetchSiteCatalogueCategory()

        this.alert('success', 'mdi-checkbox-marked-circle', this.$vuetify.lang.t('$vuetify.sites.catalogue.edit.notification.edited'))
      } catch (e) {
        if (!this._.isUndefined(e.response?.status)) {
          switch (parseInt(e.response.status)) {
            // catalogue category name already exists
            case 302:
              this.alert('warning', 'mdi-alert', this.$vuetify.lang.t('$vuetify.sites.catalogue.edit.notification.exist'))
              break
            // invalid parameters
            case 400:
              this.alert('warning', 'mdi-alert', this.$vuetify.lang.t('$vuetify.sites.catalogue.edit.notification.fail'))
              break
            // site not found
            case 404:
              await this.$router.push({
                name: 'Home',
                params: {
                  alert: {
                    enable: true,
                    dismissible: true,
                    icon: 'mdi-alert',
                    type: 'warning',
                    message: this.$vuetify.lang.t('$vuetify.sites.catalogue.edit.notification.site_not_found')
                  }
                }
              })
              break
            // product family not found
            case 410:
              this.alert('warning', 'mdi-alert', this.$vuetify.lang.t('$vuetify.sites.catalogue.edit.notification.product_family_not_found'))
              break
            // product not found
            case 412:
              this.alert('warning', 'mdi-alert', this.$vuetify.lang.t('$vuetify.sites.catalogue.edit.notification.product_not_found'))
              break
            // site catalogue category not found
            case 501:
              await this.$router.push({
                name: 'SiteCatalogueList',
                params: {
                  alert: {
                    enable: true,
                    dismissible: true,
                    icon: 'mdi-alert',
                    type: 'warning',
                    message: this.$vuetify.lang.t('$vuetify.sites.catalogue.edit.notification.catalogue_not_found')
                  }
                }
              })
              break
            // default
            default:
              this.alert('error', 'mdi-alert-octagon', this.$vuetify.lang.t('$vuetify.sites.catalogue.edit.notification.unknown_save'))
          }
          this.Sentry.captureException(e)
        }
      } finally {
        this.ui.loading = false
        this.ui.disabled = false
      }
    }
  },
  created () {
    this.breadcrumbs = [
      {
        text: this.$vuetify.lang.t('$vuetify.home.name'),
        disabled: false,
        to: { name: 'Home' }
      },
      {
        text: this.$vuetify.lang.t('$vuetify.sites.name'),
        disabled: true,
        to: { name: 'Home' }
      },
      {
        id: 1,
        text: this.site_name,
        disabled: true
      },
      {
        text: this.$vuetify.lang.t('$vuetify.sites.catalogue.name'),
        disabled: false,
        to: { name: 'SiteCatalogueList' }
      },
      {
        text: this.$vuetify.lang.t('$vuetify.sites.catalogue.edit.name'),
        disabled: true
      }
    ]
    this.form.rules = {
      name: [
        value => !!value || this.$vuetify.lang.t('$vuetify.global.required'),
        value => (value && value.length <= 200) || this.$vuetify.lang.t('$vuetify.global.max_char')
      ],
      icon: [
        value => !value || value.size < 2000000 || this.$vuetify.lang.t('$vuetify.product.products.edit.ui.ui.form.general.inputs.image.rules.size')
      ]
    }
  },
  mounted () {
    this.fetchFamiliesAvailable()
    this.fetchSiteCatalogueCategory()
  }
}
</script>
<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';

.v-card .hover-effect {
  transition: box-shadow .4s ease-in-out;
}

.infiniteLoading {
  text-align: center;
  position: absolute;
  z-index: 9;
  border-radius: 5px;
  left: calc(50% - 45px);
  top: calc(50% - 18px);
}

.opacity {
  opacity: 0.6
}

</style>
